import React, { useRef, useState } from "react";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { logout } from "../../services/auth.service";
import { useCurrentUserAccount } from "../../hooks/commonDataUtils";
import { ProfileCircle } from "../../components/ProfileCircle";
import { translate } from "@ollie-sports/i18n";

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      history.push("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        variant: "error"
      });
    }
  };

  const { data: account } = useCurrentUserAccount();

  if (!account) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <ProfileCircle accountInfo={account} size={30} />

        <Hidden smDown>
          <Typography style={{ paddingLeft: 12, color: "white" }} variant="h6" color="inherit">
            {account.firstName} {account.lastName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem component={RouterLink} to="/app/account">
          Account
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>{translate({ defaultMessage: "Logout" })}</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
